// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: center;

  padding: 60px 0;
}

.setting-card {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 40px 20px;
  text-align: center;
  width: 100%;
  max-width: 250px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.setting-card:hover {
  transform: translateY(-5px);
}

.card-icon {
  font-size: 40px;
  color: #6746af;
  margin-bottom: 10px;
}

h3 {
  font-size: 18px;
  color: #333;
}
`, "",{"version":3,"sources":["webpack://./src/components/Setting/Setting.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,SAAS;EACT,uBAAuB;EACvB,mBAAmB;;EAEnB,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,sBAAsB;EACtB,kBAAkB;EAClB,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;EACX,gBAAgB;EAChB,wCAAwC;EACxC,sCAAsC;AACxC;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,eAAe;EACf,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,WAAW;AACb","sourcesContent":[".settings-container {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 20px;\n  justify-content: center;\n  align-items: center;\n\n  padding: 60px 0;\n}\n\n.setting-card {\n  background-color: #f9f9f9;\n  border: 1px solid #ddd;\n  border-radius: 8px;\n  padding: 40px 20px;\n  text-align: center;\n  width: 100%;\n  max-width: 250px;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n  transition: transform 0.2s ease-in-out;\n}\n\n.setting-card:hover {\n  transform: translateY(-5px);\n}\n\n.card-icon {\n  font-size: 40px;\n  color: #6746af;\n  margin-bottom: 10px;\n}\n\nh3 {\n  font-size: 18px;\n  color: #333;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
